import { memo } from "react";
import indexLess from "./css/dg-copyright.less";
import faSvg from "../assets/img/fa.svg";
import govPng from "../assets/img/gov.png";

interface PropsType {
  paddingTop?: string;
  paddingBottom?: string;
  style?: React.CSSProperties | undefined;
}

export default memo(
  ({ paddingTop = "16px", paddingBottom = "16px", style = {} }: PropsType) => {
    return (
      <div
        id={`${indexLess.copyRight}`}
        className={`w-[100%] mx-[auto] text-center t-[12]`}
        style={{
          paddingTop,
          paddingBottom,
          ...style,
        }}
      >
        <p className=" t-[#B7B9BE]">
          <span className="pb-[4] border-b-[1] border-[#D5DAE5] border-solid t-[12]">
            <img src={faSvg} alt="" className="vertical-middle" />
            <span className="vertical-middle">
              声明：本平台仅提供技术支持，所有保函产品均由保函下发金融机构提供，销售、理赔等服务均由本平台合作的金融机构提供
            </span>
          </span>
        </p>
        <div className="flex justify-center">
          <p className="t-[#888A90] pb-[4] pt-[8] leading-[20] t-[12] max-w-[1200] shrink-0">
            主办单位：{ window.conf.organizer } ｜
            指导单位：{ window.conf.guidance }
            ｜ 运营单位：{ window.conf.operation }
            <span className="t-[#909399] t-[12] whitespace-nowrap">
              <img width="16" src={govPng} className="ml-[6] mb-[4]" />
              <a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                className="t-[var(--ant-primary-color)] hover:underline"
              >
                { window.conf.beiAn }
              </a>
            </span>
          </p>
        </div>
      </div>
    );
  }
);
