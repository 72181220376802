import DgCard from "../../components/dg-card";
import detailLess from "./components/css/detail.less";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { Button } from "antd";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ajax from "../../assets/js/ajax";
import { downloadFile } from "../../assets/js/util";

let navigate: NavigateFunction | null = null;

export default () => {
  navigate = useNavigate();
  const params = useLocation().state as {
    id: string;
  };

  const [data, setData] = useState<any>({});

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/accountsReceivableFinancing/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res ?? {});
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] t-[#192530] pr-[4]">近期应收账款详情</span>
      </div>
      <DgCard title="账款基础信息">
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="核心企业名称">
            {data.coreEnterpriseName}
          </Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {data.coreEnterpriseCode}
          </Dglistitem>
          <Dglistitem label="账款编号">{data.accountNo}</Dglistitem>
          <Dglistitem label="资产状态">{data.assetStatusName}</Dglistitem>
          <Dglistitem label="销售合同编号">{data.saleContractNo}</Dglistitem>
          <Dglistitem label="账款金额(元)">{data.accountAmount}</Dglistitem>
          <Dglistitem label="销售日期">{data.saleDate}</Dglistitem>
          <Dglistitem label="账款到期时间">{data.accountDueDate}</Dglistitem>
          <Dglistitem label="电子债权凭证">
            {data.elecDebtVoucher && (
              <a
                className="t-[var(--ant-primary-color)] cursor-pointer"
                href={data.elecDebtVoucher}
              >
                查看
              </a>
            )}
          </Dglistitem>
          <Dglistitem label="确权时间">{data.confirmDate}</Dglistitem>
          <Dglistitem label="销售合同">
            {data.saleContract && (
              <span
                className="t-[var(--ant-primary-color)] cursor-pointer"
                onClick={() => downloadFile(data.saleContract, "销售合同")}
              >
                下载
              </span>
            )}
          </Dglistitem>
          <Dglistitem label="发票">
            {data.invoice && (
              <span
                className="t-[var(--ant-primary-color)] cursor-pointer"
                onClick={() => downloadFile(data.invoice, "发票")}
              >
                下载
              </span>
            )}
          </Dglistitem>
          <Dglistitem label="发货凭证">
            {data.deliveryVoucher && (
              <span
                className="t-[var(--ant-primary-color)] cursor-pointer"
                onClick={() => downloadFile(data.deliveryVoucher, "发货凭证")}
              >
                下载
              </span>
            )}
          </Dglistitem>
          <Dglistitem label="其他交易文件">
            {data.otherTransactionFile && (
              <span
                className="t-[var(--ant-primary-color)] cursor-pointer"
                onClick={() =>
                  downloadFile(data.otherTransactionFile, "其他交易文件")
                }
              >
                下载
              </span>
            )}
          </Dglistitem>
          <Dglistitem label="当前债权人">{data.currentDebtorName}</Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {data.currentCreditCode}
          </Dglistitem>
        </Dglist>
      </DgCard>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="!w-[160]"
          onClick={() => {
            navigate!(-1);
          }}
        >
          返回
        </Button>
      </div>
    </div>
  );
};
