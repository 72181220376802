import { useRoutes, Routes } from "react-router-dom";

import Register from "../register";
import Protocol from "../protocol";
import Layout from "../layout";
import Login from "../login";
import Home from "../view/home";
import Index from "../view/index";
import Help from "../view/help/index";
import HelpDetail from "../view/help/detail";

// 安全中心
import Company from "../view/account/company";
import Companyedit from "../view/account/companyEdit";
import Account from "../view/account/index";
import ForgotPasw from "../view/account/forgotPasw";
import ForgetPwdLast from "../view/account/forgetPwdLast";
import ModifyPasw from "../view/account/modifyPasw";

// 费用管理
import Order from "../view/order/index";
import OrderDetail from "../view/order/detail";

// 保函管理
import Procceedings from "../view/baohan/proceedings";
import ProceedingDetail from "../view/baohan/proceedingDetail";
import Invoices from "../view/baohan/invoices";
import InvoicesDetail from "../view/baohan/invoiceDetail";
import EngContract from "../view/baohan/engContract";
import EngConDetail from "../view/baohan/engConDetail";
import EngQuality from "../view/baohan/engQuality";
import EngQuaDetail from "../view/baohan/engQuaDetail";
import NmgPay from "../view/baohan/nmgPay";
import NmgPayDetail from "../view/baohan/nmgPayDetail";
import GovContract from "../view/baohan/govContract";
import GovConDetail from "../view/baohan/govConDetail";
import Safety from "../view/baohan/safety";
import SafetyDetail from "../view/baohan/safetyDetail";
import Customs from "../view/baohan/customs";
import CustomsDetail from "../view/baohan/customsDetail";

// 事故预防服务
import AccidentOrder from "../view/accident/order";
import AccidentConfigure from "../view/accident/configure";
import AccidentService from "../view/accident/service";
import AccidentDetail from "../view/accident/detail";
import AccidentComplaint from "../view/accident/complaint";
import AccidentComplaintList from "../view/accident/complaintList";
import AccidentComplaintDetail from "../view/accident/complaintDetail";
import AccidentProject from "../view/accident/project";
import AccidentPay from "../view/accident/pay";

// 供应链金融管理
import SupplyReceives from "../view/supply/receives"
import SupplyReceivesDetail from "../view/supply/receivesDetail"
import SupplyDebtDetail from "../view/supply/debtDetail"
import SupplyCores from "../view/supply/cores"
import SupplyCoreDetail from "../view/supply/coreDetail"

import Recharge from "../view/recharge/index";
import RechargePay from "../view/recharge/pay";

import Invoice from "../view/invoice/index";
import InvoiceDetail from "../view/invoice/detail";

import Product from "../view/product/index";
import ProductDetail from "../view/product/productDetail";
import Secretkey from "../view/product/secretkey";
import MyProducts from "../view/product/myProducts";
import ProductBill from "../view/product/bill";

const GetAllRoutes = () => {
  const route = useRoutes([
    {
      path: "/",
      index: true,
      element: <Index />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/home",
      element: <Home></Home>,
    },
    {
      path: "/admin",
      element: <Layout></Layout>,
      children: [
        {
          path: "/admin",
          element: <Account></Account>,
        },
        {
          path: "/admin/company",
          element: <Company></Company>,
        },
        {
          path: "/admin/modifyPasw",
          element: <ModifyPasw></ModifyPasw>,
        },
        {
          path: "/admin/companyEdit",
          element: <Companyedit></Companyedit>,
        },
        {
          path: "/admin/secretkey",
          element: <Secretkey></Secretkey>,
        },
        {
          path: "/admin/product",
          element: <Product></Product>,
        },
        {
          path: "/admin/productDetail",
          element: <ProductDetail></ProductDetail>,
        },
        {
          path: "/admin/myProducts",
          element: <MyProducts></MyProducts>,
        },
        {
          path: "/admin/productBill",
          element: <ProductBill></ProductBill>,
        },
        // ProductBill
        // MyProducts
        // ProductDetail
        {
          path: "/admin/order",
          element: <Order></Order>,
        },
        {
          path: "/admin/order/detail",
          element: <OrderDetail></OrderDetail>,
        },
        {
          path: "/admin/engContract",
          element: <EngContract></EngContract>,
        },
        {
          path: "/admin/engContract/detail",
          element: <EngConDetail></EngConDetail>,
        },
        {
          path: "/admin/engQuality",
          element: <EngQuality></EngQuality>,
        },
        {
          path: "/admin/engQuality/detail",
          element: <EngQuaDetail></EngQuaDetail>,
        },
        {
          path: "/admin/nmgPay",
          element: <NmgPay></NmgPay>,
        },
        {
          path: "/admin/nmgPay/detail",
          element: <NmgPayDetail></NmgPayDetail>,
        },
        {
          path: "/admin/govContract",
          element: <GovContract></GovContract>,
        },
        {
          path: "/admin/govContract/detail",
          element: <GovConDetail></GovConDetail>,
        },
        {
          path: "/admin/safety",
          element: <Safety></Safety>,
        },
        {
          path: "/admin/safety/detail",
          element: <SafetyDetail></SafetyDetail>,
        },
        {
          path: "/admin/customs",
          element: <Customs></Customs>,
        },
        {
          path: "/admin/customs/detail",
          element: <CustomsDetail></CustomsDetail>,
        },
        {
          path: "/admin/procceedings",
          element: <Procceedings></Procceedings>,
        },
        {
          path: "/admin/procceedings/detail",
          element: <ProceedingDetail></ProceedingDetail>,
        },
        {
          path: "/admin/invoices",
          element: <Invoices></Invoices>,
        },
        {
          path: "/admin/invoices/detail",
          element: <InvoicesDetail></InvoicesDetail>,
        },
        {
          path: "/admin/recharge",
          element: <Recharge></Recharge>,
        },
        {
          path: "/admin/rechargePay",
          element: <RechargePay></RechargePay>,
        },
        {
          path: "/admin/invoice",
          element: <Invoice></Invoice>,
        },
        {
          path: "/admin/invoice/detail",
          element: <InvoiceDetail></InvoiceDetail>,
        },
        {
          path: "/admin/accidentOrder",
          element: <AccidentOrder></AccidentOrder>,
        },
        {
          path: "/admin/accidentConfigure",
          element: <AccidentConfigure></AccidentConfigure>,
        },
        {
          path: "/admin/accidentService",
          element: <AccidentService></AccidentService>,
        },
        {
          path: "/admin/accident/detail",
          element: <AccidentDetail></AccidentDetail>,
        },
        {
          path: "/admin/accident/complaint",
          element: <AccidentComplaint></AccidentComplaint>,
        },
        {
          path: "/admin/accident/complaintList",
          element: <AccidentComplaintList></AccidentComplaintList>,
        },
        {
          path: "/admin/accident/complaintDetail",
          element: <AccidentComplaintDetail></AccidentComplaintDetail>,
        },
        {
          path: "/admin/accident/project",
          element: <AccidentProject></AccidentProject>,
        },
        {
          path: "/admin/accident/pay",
          element: <AccidentPay></AccidentPay>,
        },
        {
          path: "/admin/supplyReceives",
          element: <SupplyReceives></SupplyReceives>,
        },
        {
          path: "/admin/supply/receivesDetail",
          element: <SupplyReceivesDetail></SupplyReceivesDetail>,
        },
        {
          path: "/admin/supply/debtDetail",
          element: <SupplyDebtDetail></SupplyDebtDetail>,
        },
        {
          path: "/admin/supplyCores",
          element: <SupplyCores></SupplyCores>,
        },
        {
          path: "/admin/supply/coreDetail",
          element: <SupplyCoreDetail></SupplyCoreDetail>,
        },
      ],
    },
    {
      path: "/help",
      element: <Help></Help>,
    },
    {
      path: "/help/detail",
      element: <HelpDetail></HelpDetail>,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/protocol",
      element: <Protocol />,
    },
    // Protocol
    {
      path: "/forgotPasw",
      element: <ForgotPasw />,
    },
    {
      path: "/forgetPwdLast",
      element: <ForgetPwdLast />,
    },
    //
  ]);
  return route;
};

export default GetAllRoutes;

// export default function () {
// 	return (
// 		<Routes>
// 			<GetAllRoutes></GetAllRoutes>
// 		</Routes>
// 	);
// }
